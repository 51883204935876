import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from 'ng2-translate';

import { UserService } from './user.service';
import { UserProfile } from './user.profile';

import { AuthGuard } from './auth.guard';

@NgModule({
    imports: [
        RouterModule,
        TranslateModule
    ],
    providers: [
        AuthGuard,
        UserService,
        UserProfile
    ],
})
export class UserModule { }
