import { Component, AfterViewInit, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { BillingService } from './../../billing/billing.service';

import { UserProfile } from './../../user/user.profile';
import { UserService } from './../../user/user.service';

import { TranslateService } from 'ng2-translate';

declare var Godlike:any;

@Component({
    selector: 'nav',
    templateUrl: 'nav.component.html'
})

export class NavComponent implements AfterViewInit, OnInit {
    date: Date = new Date();

    constructor(
        public profile: UserProfile,
        public translate: TranslateService,
        private service: BillingService,
        private user: UserService) { }

    ngAfterViewInit() {
        Godlike.initNavbar();
        Godlike.initNavbarSide();
        Godlike.initNavbarFullscreen();
        Godlike.initNavbarDropEffect1();
        Godlike.initPluginStickySidebar();
    }

    ngOnInit() {}

    changeLanguage(lang: string) {
        this.user.changeLanguage(lang);
    }
}