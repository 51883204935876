import { Component, EventEmitter, OnInit, OnDestroy, Input, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs/Observable';

import { BillingService } from './../billing/billing.service';
import { UserProfile } from '../user/user.profile';
import { TranslateService } from 'ng2-translate';

@Component({
    selector: '[category-article]',
    templateUrl: 'category-article.component.html'
})

export class CategoryArticleComponent implements OnInit, OnDestroy {
    @Input() article: any;
    @Input() category: any;
    @Input() level: number;
    @Output() onBuy = new EventEmitter<any>();

    private running: boolean;
    loading: boolean;

    coolTime: any = { days: '0', hours: '0', minutes: '0', seconds: '0' };
    endTime: any = { days: '0', hours: '0', minutes: '0', seconds: '0' };
    startTime: any = { days: '0', hours: '0', minutes: '0', seconds: '0' };

    private subscription: Subscription = new Subscription();

    /*
     * Evènement de construction
     */
    constructor(
        private profile: UserProfile,
        private service : BillingService,
        private translate: TranslateService) { }

    /*
     * Evènement d'initialisation
     */
    ngOnInit(): void {
        this.loading = true;
        this.article.count = 1;

        if (this.article.cooltime == 0) {
            this.onCompleted(0);
            return;
        }

        this.subscription.add(this.service.getArticleCootime(this.article.id).subscribe(
            cooltime => this.onCompleted(cooltime)
        ));
    }

    /*
     * Evènement opération réussi
     */
    onCompleted(tick: number) {
        this.article.userCooltime = new Date(Date.now() + tick * 1000);
        this.loading = false;
        this.counter();
    }

    /*
     * Evènement de destruction
     */
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    /*
     * Achat de l'article
     */
    buy(): void {
        this.onBuy.emit({ product: this.article, price: this.getPrice() });
    }

    /*
     * Compteur de recharge
     */
    counter() {
        try {
            /// Compte à rebours temps de recharge
            if (this.article.cooltime != 0) {
                this.coolTime = this.getTime(this.article.userCooltime);
            }

            /// Compte à rebours périodique
            if (this.article.isPeriodic) {
                    this.startTime = this.getTime(this.article.beginPeriod);
                    this.endTime = this.getTime(this.article.endPeriod);
            }

            /// Répéter la tache
            if (!this.running) {
                if (this.article.cooltime == 0 
                    && !this.article.isPeriodic) return;

                this.subscription.add(Observable.interval(1000).subscribe(
                    (x) => {
                    this.counter()
                    this.running = true;
                }));
            }
        }
        catch { }
    }

    /*
     * Calcul la solde de l'article
     */
    getBalance(): number {
        return Math.round(((this.category.balance * this.article.balance) - 1) * 100);
    }

    /*
     * Récupére la description de l'article
     */
    getDescription() {
        var result = this.article.langs.find(u => u.lang == this.translate.currentLang);
        if (!result) {
            return "NO_DESCRIPTION_" + this.article.id;
        }
        return result.description;
    }

    /*
     * Calcule le temps en recharge en heure
     */
    getCooltime(): number {
        return this.article.cooltime / 3600;
    }

    /*
     * Calcul le prix de l'article
     */
    getPrice(): number {
        return Math.ceil((this.category.balance * this.article.balance) * this.article.price);
    }

    /*
     * Récupére le nom de l'article
     */
    getName() {
        var result = this.article.langs.find(u => u.lang == this.translate.currentLang);
        if (!result) {
            return "NO_NAME_" + this.category.id;
        }
        return result.name;
    }

    getRankClass(level: number) {
        return 'reward-' + level;
    }

    /*
     * Format le temps en compteur
     */
    getTime(date: any): any {
        const now = Date.now();
        const tick = Date.parse(date);
        const distance = tick - now;

        if (distance < 0) {
            return { days: 0, hours: 0, minutes: 0, seconds: 0 };
        }

        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor(distance % (1000 * 60 * 60 * 24) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        return { days: days, hours: hours, minutes: minutes, seconds: seconds };
    }

    /*
     * Vérifie si l'article est disponible
     */
    isAvailable(): boolean {
        if (!this.article.enable || !this.category.enable) return false;

        var now = new Date();
        var start = new Date(this.article.beginPeriod);
        var end = new Date(this.article.endPeriod);

        return !this.article.isPeriodic || (this.article.isPeriodic && start < now && now < end);
    }

    /*
     * Vérifie si l'article est disponible à l'achat
     */
    isAvailableForBuy(): boolean {
        return this.isAvailable() && !this.isReloading();
    }

    /*
     * Vérifie si l'article est soldé
     */
    isBalanced(): boolean {
        return this.article.balance != 1 || this.category.balance != 1;
    }

    /*
     * Vérifie si l'objectif peut être acheté
     */
    isCanBuy(): boolean {
        return this.isAvailable() && !this.isReloading() && !this.isInsufficient();
    }

    /*
     * Vérifie si la solde permet l'achat
     */
    isInsufficient(): boolean {
        return (this.getPrice() * this.article.count) > this.profile.user.money;
    }

    /*
     * Vérifie si l'article est périodique
     */
    isPeriodic(): boolean {
        return (this.article.isPeriodic);
    }

    /*
     * Vérifie si l'article est en rechargement
     */
    isReloading(): boolean {
        return this.article.userCooltime > new Date();
    }

    /*
     * Vérifie si l'article est bientôt disponible
     */
    isSoon(): boolean {
        if (!this.article.isPeriodic || !this.article.enable || !this.category.enable) return false;

        var begin = new Date();
        var now = new Date();
        var start = new Date(this.article.beginPeriod);

        begin.setDate(start.getDate() - 86400000);
        return start > now && now >= begin;
    }

    /*
     * Vérifie si la solde permet l'achat
     */
    isSufficient(): boolean {
        return !this.isInsufficient();
    }

    /*
     * Vérifie la indisponibilité de l'objectif
     */
    isUnavailable(): boolean {
        return !this.isAvailable() && !this.isSoon();
    }
}