import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'replace'
})

export class ReplacePipe implements PipeTransform {
    transform(original: string, src: string, dst: string): string {
        return original.replace(src, dst);
    }
}
