import { Component, OnInit, OnDestroy, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import { BillingService } from '../billing/billing.service';

declare var $: any;

@Component({
    moduleId: module.id,
    templateUrl: 'home.component.html'
})

export class HomeComponent implements OnInit, OnDestroy {
    amount: number;
    products: any[] = [];
    errors: Error[];
    level: number;
    loading: boolean = true;

    product: any = { name: '', quantity: 1 };
    productPrice: number;
    productType: number;

    private subscription: Subscription = new Subscription();

    /*
     * Evènement de construction
     */
    constructor(private service: BillingService) { }

    /*
     * Evènement d'initialisation
     */
    ngOnInit() {
        this.loading = true;
        this.subscription.add(this.service.getObjective().subscribe(
            response => {
                this.amount = response.amount;
                this.level = response.level;

                this.subscription.add(this.service.getPopulars().subscribe(
                    response => this.onSuccess(response),
                    error =>    this.onError(error)
                ));
        }));
    }

    getProgress() {
        return (this.amount % 10) * 10;
    }

    getNextLevel() {
        return this.level + 1
    }

    /*
     * Evènement de destruction
     */
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    /*
     * Affiche la fenêtre d'achat de l'article
     */
    onBuy(event: any): void {
        this.product = event.product;
        this.productPrice = event.price;
        this.productType = event.type;

        $('#buyModal').modal('show');
    }

    /*
     * Erreur du serveur de boutique
     */
    onError(errors: Error[]) {
        this.errors = errors;
        this.loading = false;
    }

    /*
     * Réussite de l'opération
     */
    onSuccess(response: any) {
        this.products = response;
        this.loading = false;
    }
}