import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filter'
})

export class FilterPipe implements PipeTransform {
    transform(items: any[], field: string, value: any): any[] {
        if (!items) return [];
        if (value === null) return items;
        if (typeof value === 'undefined') return items;

        return items.filter(it => {
            if (typeof it[field] === 'string') return it[field].indexOf(value) !== -1;
            return it[field] === value;
        });
    }
}
