import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';

import { UserProfile } from './user.profile';
import { IProfile } from './user.model';

@Injectable()
export class UserService {
    constructor(
        private http: HttpClient,
        private authProfile: UserProfile) { }

    addPhone(phoneNumber: string) {
        var credentials = {
            PhoneNumber: phoneNumber
        };

        return this.http.post('/api/manage/addphonenumber', credentials);
    }

    changeEmail(email: string) {
        var credentials = {
            Email: email
        };
        
        return this.http.post('/api/manage/changeemail', credentials);
    }

    changeEmailConfirm(code: string, oldEmail: string, newemail: string) {
        var credentials = {
            code: code,
            oldEmail: oldEmail,
            newemail: newemail
        };

        return this.http.post('/api/manage/changeemailconfirm', credentials);
    }

    changeLanguage(language: string) {
        if (this.authProfile.language == language) return;

        this.authProfile.setLanguage(language);

        if (this.authProfile.isAuthorized()) {    
            var credentials = {
                Language: language
            };
    
            return this.http.post('/api/manage/changelanguage', credentials).toPromise();
        }

    }

    changePassword(oldpassword: string, newpassword: string) {
        var credentials = {
            OldPassword: oldpassword,
            NewPassword: newpassword
        };

        return this.http.post('/api/manage/changepassword', credentials);
    }

    changePseudo(pseudo: string) {
        var credentials = {
            Pseudo: pseudo
        };

        return this.http.post('/api/manage/changepseudo', credentials);
    }

    confirmMail(id: string, code: string) {
        var credentials = {
            userId: id,
            code: code
        };

        return this.http.post('/api/account/ConfirmEmail', credentials);
    }

    forgotPassword(email: string) {
        var credentials = {
            Email: email
        };

        return this.http.post('/api/account/forgotpassword', credentials);
    }

    forgotUser(email: string) {
        var credentials = {
            Email: email
        };

        return this.http.post('/api/account/forgotuser', credentials);
    }

    login(userName: string, password: string) {
        var credentials = {
            UserName: userName,
            Password: password
        };

        return this.http.post('/api/account/login', credentials)
            .pipe(map(response => this.authProfile.setProfile(<IProfile>response)));
    }

    logout() {
        if (!this.authProfile.isAuthorized()) return;
        
        this.http.post('/api/account/logout', { })
            .subscribe(result => this.authProfile.resetProfile());
    }

    register(userName: string, password: string, email: string, pseudo: string, language: string) {
        var credentials = {
            UserName: userName,
            Email: email,
            Password: password,
            Pseudo: pseudo,
            Language: language
        };

        return this.http.post('/api/account/register', credentials);
    }

    requestImbc() {
        return this.http.post('/api/account/requestImbc', { });
    }

    resetPassword(password: string, email: string, code: string) {
        var credentials = {
            Email: email,
            Password: password,
            Code: code
        };

        return this.http.post('/api/account/resetpassword', credentials);
    }

    removePhone() {
        return this.http.post('/api/manage/removephonenumber', { });
    }

    setPassword(newPassword: string) {
        var credentials = {
            NewPassword: newPassword
        };

        return this.http.post('/api/manage/setpassword', credentials);
    }

    refresh(): Observable<string> {
        var credentials = {
            Token: this.authProfile.refreshToken,
            UserId: this.authProfile.user.id
        };

        return this.http.post('/api/account/refresh', credentials)
            .pipe(
                map((response) => {
                    this.authProfile.setToken(response)
                    return (<any>response).token;
                })
            );
    }

    token(id: string, server: string) {
        var credentials = {
            id: id,
            server: server
        };

        return this.http.post('/api/account/token', credentials)
            .pipe(map(response => this.authProfile.setProfile(<IProfile>response)));
    }

    verifyPhoneNumber(phoneNumber: string, code: string) {
        var credentials = {
            PhoneNumber: phoneNumber,
            Code: code
        };

        return this.http.post('/api/manage/verifyphonenumber', credentials);
    }
}
