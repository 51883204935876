import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { CustomFormsModule } from 'ng2-validation';
import { FormsModule } from '@angular/forms';
import { HttpModule, Http } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BillingModule } from './billing/billing.module';
import { ElementModule } from './element/element.module';
import { PopoverModule } from 'ngx-popover';
import { TranslateModule, TranslateStaticLoader, TranslateLoader } from 'ng2-translate';
import { UserModule } from './user/user.module';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { BuyModalComponent } from './page/buy-modal.component';
import { CategoriesComponent } from './page/categories.component';
import { CategoryComponent } from './page/category.component';
import { CategoryArticleComponent } from './page/category-article.component';
import { CategoriesItemComponent } from './page/categories-item.component';
import { GameComponent } from './account/game.component';
import { HomeComponent } from './page/home.component';
import { LoginComponent } from './account/login.component';
import { NavComponent } from './element/shared/nav.component';
import { NotFoundComponent } from './page/not-found.component';
import { PromoComponent } from './page/promo.component';
import { RefundModalComponent } from './page/refund-modal.component';
import { SummariesComponent } from './page/summaries.component';
import { SummariesItemComponent } from './page/summaries-item.component';

import { FilterPipe, ReplacePipe, TruncatePipe, SortPipe } from './_pipes/index';

import { InterceptorService } from './interceptor.service';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

export function createTranslateLoader(http: Http): TranslateStaticLoader {
	return new TranslateStaticLoader(http, './i18n', '.json' + '?cb=' + (new Date()).getTime());
}

@NgModule({
    imports: [
        AppRoutingModule,
        BillingModule,
        BrowserAnimationsModule,
        BrowserModule,
        CommonModule,
        CustomFormsModule,
        ElementModule,
        FormsModule,
        HttpModule,
        HttpClientModule,
        PerfectScrollbarModule,
        PopoverModule,
        TranslateModule.forRoot({
            provide: TranslateLoader,
            useFactory: createTranslateLoader,
            deps: [Http]
        }),
        UserModule
    ],
    declarations: [
        AppComponent,
        BuyModalComponent,
        CategoriesComponent,
        CategoriesItemComponent,
        CategoryComponent,
        CategoryArticleComponent,
        GameComponent,
        HomeComponent,
        LoginComponent,
        NotFoundComponent,
        PromoComponent,
        RefundModalComponent,
        SummariesComponent,
        SummariesItemComponent,
        FilterPipe,
        ReplacePipe,
        TruncatePipe,
        SortPipe
    ],
    providers: [
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptorService,
            multi: true
        }
    ],
    bootstrap: [
        AppComponent,
        NavComponent
    ]
})
export class AppModule { }
