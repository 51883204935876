import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { BillingService } from './../billing/billing.service';
import { UserProfile } from '../user/user.profile';

declare var $: any;

@Component({
    selector: 'refund-modal',
    templateUrl: 'refund-modal.component.html'
})

export class RefundModalComponent implements OnInit, OnDestroy {
    @Input() summary: any;
    @Output() onDelete = new EventEmitter<any>();

    loading: boolean = false;
    errors: Error[];

    private subscription: Subscription = new Subscription();

    /*
     * Evènement de construction
     */
    constructor(
        private profile: UserProfile,
        private service: BillingService) { }

    /*
     * Evènement de initialisation
     */
    ngOnInit() { }

    /*
     * Evènement de destruction
     */
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
    
    /*
     * Annuler l'achat
     */
    refund(): void {
        this.subscription.add(this.service.cancel(this.summary.id).subscribe(
            reponse =>  this.onSuccess(reponse),
            error =>    this.onError(error)
        ))
    }

    /*
     * Masque la fenêtre de remboursement
     */
    hide(): void {        
        $('#refundModal').modal('hide');
    }

    /*
     * Evènement de résussite de l'opération
     */
    onError(error: any) {
        this.errors = error;
        this.loading = false;
    }

    /*
     * Evènement de résussite de l'opération
     */
    onSuccess(response: any) {
        this.profile.setMoney(response);
        this.onDelete.emit(this.summary);
        this.hide();
    }
}