import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './page/home.component';
import { CategoriesComponent } from './page/categories.component';
import { CategoryComponent } from './page/category.component';
import { GameComponent } from './account/game.component';
import { LoginComponent } from './account/login.component';
import { NotFoundComponent } from './page/not-found.component';
import { SummariesComponent } from './page/summaries.component';

import { AuthGuard } from './user/auth.guard';
import { PromoComponent } from './page/promo.component';

const appRoutes: Routes = [
  {
      path: '',
      component: HomeComponent,
      canActivate: [AuthGuard]
  },
  {
      path: 'categories',
      component: CategoriesComponent,
      canActivate: [AuthGuard]
  },
  {
      path: 'categories/:id',
      component: CategoryComponent,
      canActivate: [AuthGuard]
  },
  {
      path: 'game',
      component: GameComponent
  },
  {
      path: 'login',
      component: LoginComponent
  },
  {
      path: 'promo',
      component: PromoComponent,
      canActivate: [AuthGuard]
  },
  {
      path: 'summaries',
      component: SummariesComponent,
      canActivate: [AuthGuard]
  },
  {
      path: '404',
      component: NotFoundComponent
  },
  {
    path: '**',
    redirectTo: '404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {}
