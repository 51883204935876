import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserService } from './user/user.service';

declare var Godlike: any;

@Component({
    selector: 'main',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
    private subscription: Subscription = new Subscription();

    constructor(
        private router: Router,
        public user: UserService) {
    }

    ngOnInit() {
        this.subscription.add(this.router.events.subscribe(
            event => {
                if (event instanceof NavigationEnd) {
                    Godlike.initPluginJarallax();
                }
            }
        ));
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
