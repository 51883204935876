import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from 'ng2-translate';

@Pipe({
    name: 'sort'
})

export class SortPipe implements PipeTransform {
    constructor(private translate: TranslateService) { }

    transform(array: any[]): any[] {
        array.sort((a: any, b: any ) => {
            return this.getName(a) > this.getName(b) ? 1 : -1;
        })
        return array;
    }

    /*
     * Récupére le nom de la catégorie
     */
    getName(a: any) {
        var result = a.langs.find(u => u.lang == this.translate.currentLang);
        if (!result) {
            return "NO_NAME_" + a.id;
        }
        return result.name;
    }
}
