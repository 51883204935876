import { Component, OnInit } from '@angular/core';

@Component({
    moduleId: module.id,
    templateUrl: 'not-found.component.html'
})

export class NotFoundComponent implements OnInit {
    constructor() { }

    ngOnInit() { }
}