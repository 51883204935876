import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { BillingService } from './../billing/billing.service';

@Component({
    moduleId: module.id,
    templateUrl: 'categories.component.html'
})

export class CategoriesComponent implements OnInit, OnDestroy {
    categories: any[] = [];
    loading: boolean;

    private subscription: Subscription = new Subscription();

    /*
     * Evènement de construction
     */
    constructor(private service : BillingService) { }

    /*
     * Evènement d'initialisation
     */
    ngOnInit() {
        this.loading = true;
        this.subscription.add(this.service.getCategories().subscribe(
            response => {
                this.categories = response;
                this.loading = false;
            }
        ));
    }

    /*
     * Evènement de destruction
     */
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}