import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { BillingService } from './../billing/billing.service';
import { TranslateService } from 'ng2-translate';

@Component({
    selector: '[category-item]',
    templateUrl: 'categories-item.component.html'
})

export class CategoriesItemComponent implements OnInit, OnDestroy {
    @Input() category: any;

    count: number;
    loading: boolean;

    private subscription: Subscription = new Subscription();

    /*
     * Evènement de construction
     */
    constructor(
        private service: BillingService,
        private translate: TranslateService) { }

    /*
     * Evènement d'initialisation
     */
    ngOnInit() {
        this.loading = true;
        this.subscription.add(this.service.getCategoryArticleCount(this.category.id).subscribe(
            response => {
                this.count = response;
                this.loading = false;
            }
        ));
    }

    /*
     * Evènement de destruction
     */
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    /*
     * Récupére le nom de la catégorie
     */
    getName() {
        var result = this.category.langs.find(u => u.lang == this.translate.currentLang);
        if (!result) {
            return "NO_NAME_" + this.category.id;
        }
        return result.name;
    }

    /*
     * Calcul la solde de la catégorie
     */
    getBalance(): number {
        return Math.round((this.category.balance - 1) * 100);
    }
}