import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { Error } from './../shared/error.model';
import { UserService } from './../user/user.service';

@Component({
    moduleId: module.id,
    templateUrl: 'game.component.html'
})

export class GameComponent implements OnInit, OnDestroy {
    errors: Error[];
    loading: boolean = false;

    private subscription: Subscription = new Subscription();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private user: UserService) { }

    ngOnInit() {
        this.loading = true;
        this.subscription.add(this.route.queryParams.subscribe(
            params => {
                if (!params['id'] || !params['server']) {
                    return this.router.navigate(['/404']);
                }

                this.subscription.add(this.user.token(params['id'], params['server']).subscribe(
                    response => this.onSuccess(),
                    error =>    this.onError(error)
                ));
        }));
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    onError(errors: Error[]) {
        this.errors = errors;
        this.loading = false;
    }

    onSuccess() {
        this.loading = false;
        this.router.navigate(['/']);
    }
}