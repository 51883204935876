import { Component, Input } from '@angular/core';
import { Error } from './../../shared/error.model';

@Component({
    selector: 'error',
    templateUrl: 'error.component.html'
})

export class ErrorComponent {
    @Input() errors: Error[] = [];
}