import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { BillingService } from './../billing/billing.service';

declare var $: any;

@Component({
    moduleId: module.id,
    templateUrl: 'summaries.component.html'
})

export class SummariesComponent implements OnInit, OnDestroy {
    errors: Error[];
    summaries: any[] = [];
    loading: boolean;

    summary: any = {};

    private subscription: Subscription = new Subscription();

    /*
     * Evènement de construction
     */
    constructor(private service : BillingService) { }

    /*
     * Evènement d'initialisation
     */
    ngOnInit() {
        this.loading = true;
        this.subscription.add(this.service.getSummaries().subscribe(
            response => {
                this.summaries = response;
                this.loading = false;
            },
            error => {
                this.errors = error;
                this.loading = false;
            }
        ));
    }

    /*
     * Evènement de destruction
     */
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    /*
     * Affiche la fenêtre d'annulation d'achat
     */
    onCancel(summary: any): void {
        this.summary = summary;

        $('#refundModal').modal('show');
    }

    onDelete(summary: any): void {
        this.summaries = this.summaries.filter(u => u != summary);
    }
}