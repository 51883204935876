import { NgModule } from '@angular/core';
import { BillingService } from './billing.service';

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [
        BillingService
    ],
})
export class BillingModule { }
