import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Response } from '@angular/http';
import { Subscription } from 'rxjs';

import { Error } from './../shared/error.model';
import { UserService } from './../user/user.service';

import { TranslateService } from 'ng2-translate';

@Component({
    moduleId: module.id,
    templateUrl: 'login.component.html'
})

export class LoginComponent implements OnInit, OnDestroy {
    errors: Error[];
    loading: boolean = false;
    model: any = {};
    returnUrl: string;

    private subscription: Subscription = new Subscription();

    constructor(
        private userService: UserService,
        private translate: TranslateService,
        private route: ActivatedRoute,
        private router: Router) { }

    ngOnInit() {
        this.route.queryParams.subscribe(data => {
            this.returnUrl = data['returnUrl'] || '/';
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    login() {
        this.loading = true;
        this.subscription.add(this.userService.login(this.model.username, this.model.password).subscribe(
            response => this.onSuccess(),
            error =>    this.onError(error)
        ));
    }

    onError(errors: any) {
        this.errors = errors;
        this.loading = false;
    }

    onSuccess() {
        this.router.navigate([this.returnUrl]);
        this.loading = false;
    }
}