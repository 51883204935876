import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';

import { BillingService } from './../billing/billing.service';
import { UserProfile } from '../user/user.profile';

declare var $: any;

@Component({
    selector: 'buy-modal',
    templateUrl: 'buy-modal.component.html'
})

export class BuyModalComponent implements OnInit, OnDestroy {
    @Input() product: any;
    @Input() price: number;

    loading: boolean = false;
    errors: Error[];

    private subscription: Subscription = new Subscription();

    /*
     * Evènement de construction
     */
    constructor(
        private profile: UserProfile,
        private service: BillingService) { }

    /*
     * Evènement de initialisation
     */
    ngOnInit() { }

    /*
     * Evènement de destruction
     */
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
    
    /*
     * Achat de l'article
     */
    buy(): void {
        this.loading = true;
        this.errors = [];
        this.subscription.add(this.service.purchase(this.product.id, this.product.count).subscribe(
            response => this.onSuccess(response),
            error =>    this.onError(error)
        ))
    }

    /*
     * Masque la fenêtre d'achat
     */
    hide(): void {        
        $('#buyModal').modal('hide');
    }

    /*
     * Evènement de résussite de l'opération
     */
    onError(error: any) {
        this.errors = error;
        this.loading = false;
    }

    /*
     * Evènement de résussite de l'opération
     */
    onSuccess(response: any) {
        this.product.sold += this.product.count;
        this.profile.setMoney(response);

        if (this.product.cooltime != 0) {
            this.product.userCooltime = new Date(Date.now() + this.product.cooltime * 1000);
        }
        
        this.loading = false;
        this.hide();
    }
}