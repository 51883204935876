import { Injectable } from "@angular/core";
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { Error } from './shared/error.model';
import { Observable, BehaviorSubject } from "rxjs/Rx";
import { catchError, switchMap, finalize } from "rxjs/operators";

import { UserService } from "./user/user.service";
import { UserProfile } from "./user/user.profile";

@Injectable()
export class InterceptorService implements HttpInterceptor {
    handle: boolean = false;
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(
        private router: Router,
        private service: UserService,
        private user: UserProfile) {}

        addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
            return req.clone({
                setHeaders: {
                    'Authorization': 'Bearer ' + token,
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
            }})
        }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(this.addToken(req, this.user.getAuthToken()))
            .pipe(
                catchError((error, caught) => {
                    if (error.status === 400) {
                        return Observable.throw(error.error);
                    }
                    else if (error.status == 401) {
                        return this.handle401Error(req, next);
                    } else {
                        return Observable.throw([ new Error(error.status.toString(), error.statusText) ]);
                    }
                })
            );
    }

    handle401Error(req: HttpRequest<any>, next: HttpHandler) {
        if (!this.handle) {
            this.handle = true;

            // Reset here so that the following requests wait until the token
            // comes back from the refreshToken call.
            this.tokenSubject.next(null);
            
            return this.service.refresh()
                .pipe(
                    switchMap((newToken: string, index: number) => {
                        if (newToken) {
                            this.tokenSubject.next(newToken);
                            return next.handle(this.addToken(req, newToken));
                        }
    
                        // If we don't get a new token, we are in trouble so logout.
                        return this.logoutUser();
                    }),
                    catchError((error, caught) => {
                        return this.logoutUser();
                    }),
                    finalize(() => {
                        this.handle = false;
                    })
                )
                .toPromise();

        } else {
            return this.tokenSubject
                .filter(token => token != null)
                .take(1)
                .switchMap(token => {
                    return next.handle(this.addToken(req, token));
                });
        }
    }

    logoutUser() {
        this.router.navigate(['']);
        this.user.resetProfile();
        return Observable.empty();
    }
}