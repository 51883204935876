import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class BillingService {
    constructor(
        private http: HttpClient) { }

    cancel(id: number) {        
        return this.http.post('/api/billing/cancel', { id: id })
            .pipe(map(res => { return <any>res; }));
    }

    getArticle(id: number) {
        return this.http.get('/api/billing/article/' + id)
            .pipe(map(res => { return <any>res; }));
    }

    getArticleCootime(id: number) {
        return this.http.get('/api/billing/article/' + id + '/cooltime')
            .pipe(map(res => { return <any>res; }));
    }

    getCategory(id: number) {
        return this.http.get('/api/billing/category/' + id)
            .pipe(map(res => { return <any>res; }));
    }

    getCategoryProducts(id: number) {
        return this.http.get('/api/billing/category/' + id + '/articles')
            .pipe(map(res => { return <any>res; }));
    }

    getCategoryArticleCount(id: number) {
        return this.http.get('/api/billing/category/' + id + '/count')
            .pipe(map(res => { return <any>res; }));
    }

    getCategories() {
        return this.http.get('/api/billing/categories')
            .pipe(map(res => { return <any>res; }));
    }

    getObjective() {
        return this.http.get('/api/billing/objective')
            .pipe(map(res => { return <any>res; }));
    }

    getPopulars() {
        return this.http.get('/api/billing/populars')
            .pipe(map(res => { return <any>res; }));
    }

    getSummaries() {
        return this.http.get('/api/billing/summaries/')
            .pipe(map(res => { return <any>res; }));
    }

    purchase(id: number, quantity: number) {
        let credentials = {
            id: id,
            quantity: quantity
        }
        
        return this.http.post('/api/billing/purchase', credentials)
            .pipe(map(res => { return <any>res; }));
    }

    promo(code: string) {
        let credentials = {
            Code: code
        }

        return this.http.post('/api/billing/promo', credentials)
            .pipe(map(res => { return <any>res; }));
    }
}