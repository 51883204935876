import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { BillingService } from '../billing/billing.service';
import { NgForm } from '@angular/forms';
import { UserProfile } from '../user/user.profile';

declare var $: any;

@Component({
    moduleId: module.id,
    templateUrl: 'promo.component.html'
})

export class PromoComponent implements OnInit, OnDestroy {
    model: any = {};
    errors: Error[];
    loading: boolean;
    completed: boolean;

    private subscription: Subscription = new Subscription();

    /*
     * Evènement de construction
     */
    constructor(
        private profile: UserProfile,
        private service: BillingService) { }

    /*
     * Evènement d'initialisation
     */
    ngOnInit() { }

    /*
     * Evènement de destruction
     */
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    promo(f: NgForm) {
        this.loading = true;
        this.subscription.add(this.service.promo(this.model.code).subscribe(
            response => this.onSuccess(f, response),
            errors =>   this.onError(f, errors)
        ));
    }

    /*
     * Erreur du serveur de boutique
     */
    onError(f: NgForm, errors: Error[]) {
        f.resetForm();
        this.errors = errors;
        this.loading = false;
    }

    /*
     * Réussite de l'opération
     */
    onSuccess(f: NgForm, response) {
        f.resetForm();
        this.profile.setMoney(response);
        this.completed = true;
        this.loading = false;
    }
}