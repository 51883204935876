import { Component, EventEmitter, OnInit, OnDestroy, Output } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';

import { BillingService } from '../billing/billing.service';
import { TranslateService } from 'ng2-translate';

declare var $: any;

@Component({
    moduleId: module.id,
    templateUrl: 'category.component.html'
})

export class CategoryComponent implements OnInit, OnDestroy {
    amount: number;
    articles: any[] = [];
    category: any = { balance: 1 };
    errors: Error[];
    level: number;
    loading: boolean = true;

    product: any = { name: '', quantity: 1 };
    productPrice: number;

    private subscription: Subscription = new Subscription();

    /*
     * Evènement de construction
     */
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private service: BillingService,
        private translate: TranslateService) { }

    /*
     * Evènement d'initialisation
     */
    ngOnInit() {
        this.loading = true;
        this.subscription.add(this.route.params.subscribe(
            params => {
                this.subscription.add(this.service.getCategory(+params['id']).subscribe(
                    category => {
                        this.category = category;
                        this.subscription.add(this.service.getObjective().subscribe(
                            response => {
                                this.amount = response.amount;
                                this.level = response.level;

                                this.subscription.add(this.service.getCategoryProducts(+params['id']).subscribe(
                                    response => this.onSuccess(response),
                                    error =>    this.onError(error)
                                ));
                            },
                            () => this.router.navigate(['/404'])
                        ));
                    },
                    () => this.router.navigate(['/404'])
                ));
            }
        ));
    }

    /*
     * Evènement de destruction
     */
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    getProgress() {
        return (this.amount % 10) * 10;
    }

    getRankClass(level: number) {
        return 'reward-' + level;
    }

    /*
     * Calcul la solde de l'article
     */
    getBalance(): number {
        return Math.round((this.category.balance - 1) * 100);
    }

    /*
     * Affiche la fenêtre d'achat de l'article
     */
    onBuy(event: any): void {
        this.product = event.product;
        this.productPrice = event.price;

        $('#buyModal').modal('show');
    }

    /*
     * Récupére le nom de la catégorie
     */
    getName() {
        var result = this.category.langs.find(u => u.lang == this.translate.currentLang);
        if (!result) {
            return "NO_NAME_" + this.category.id;
        }
        return result.name;
    }

    /*
     * Erreur du serveur de boutique
     */
    onError(errors: Error[]) {
        this.errors = errors;
        this.loading = false;
    }

    onSuccess(response: any) {
        this.articles = response;
        this.loading = false;
    }
}