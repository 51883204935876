import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';

import { BillingService } from './../billing/billing.service';
import { TranslateService } from 'ng2-translate';

@Component({
    selector: '[summary-item]',
    templateUrl: 'summaries-item.component.html'
})

export class SummariesItemComponent implements OnInit, OnDestroy {
    @Input() summary: any;
    @Output() onCancel = new EventEmitter<any>();

    loading: boolean = true;
    product: any;

    private subscription: Subscription = new Subscription();

    /*
     * Evènement de construction
     */
    constructor(
        private service : BillingService,
        private translate: TranslateService) { }

    /*
     * Achat de l'article
     */
    cancel(): void {
        this.onCancel.emit(this.summary);
    }

    ngOnInit() {
        this.subscription.add(this.service.getArticle(this.summary.productId).subscribe(
            response => {
                this.product = response;
                this.loading = false;
            }
        ));
    }

    /*
     * Evènement de destruction
     */
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    /*
     * Récupére la description du produit
     */
    getDescription() {
        this.product.langs.forEach(element => {
            if (this.translate.currentLang == element.lang) {
                return element.name;
            }
        });        
        return "NO_DESCRIPTION_" + this.product.id;
    }

    /*
     * Récupére le nom du produit
     */
    getName() {
        var result = this.product.langs.find(u => u.lang == this.translate.currentLang);
        if (!result) {
            return "NO_NAME_" + this.product.id;
        }
        return result.name;
    }
}